<script lang="ts" setup>
import { mdiMenu as mdiMenu } from "@mdi/js";
import { reactive } from "vue";
import { useRouter } from "vue-router";
import { useDisplay } from "vuetify";

import AppHelpDialog from "#components/dialogs/AppHelpDialog.vue";
import ReviewRubricDialog from "#components/dialogs/ReviewRubricDialog.vue";
import { useAppTheme } from "#composables/use-app-theme";
import { useAuthAccount } from "#composables/use-auth-account";
import { useDialog } from "#composables/use-dialog";
import { appIcons } from "#composables/use-icons";
import { appConfig } from "#config/app";

const emit = defineEmits<{
  (e: "toggle-drawer"): void;
}>();

const { dark, icon: themeIcon, toggleTheme } = useAppTheme();
const { mobile } = useDisplay();

const accountStore = reactive(useAuthAccount());

const logoutConfirmDialog = useDialog();
const reviewRubricDialog = useDialog();
const helpDialog = useDialog();

const router = useRouter();

const handleLogout = () => {
  logoutConfirmDialog.hide();
  // Logout functionality is handled on logout page
  router.push("/auth/logout");
};
</script>

<template>
  <VAppBar :color="!dark ? 'primary' : undefined" elevation="2">
    <template #prepend>
      <VBtn :active="false" icon :to="accountStore.authenticated ? '/dashboard' : '/'">
        <VAvatar class="pa-2" color="background" image="/logo.png" />
      </VBtn>
    </template>

    <template #title>
      <VAppBarTitle text="Cantate Portal">
        <template v-if="accountStore.isComposer">
          <VBtn class="ml-4" to="/composer/submissions" variant="text">Submissions</VBtn>
        </template>
      </VAppBarTitle>
    </template>

    <template #append>
      <template v-if="appConfig.development">
        <VBtn density="comfortable" href="http://localhost:8025/#" icon target="_blank">
          <template #default>
            <img
              alt="MailHog logo"
              class="opacity-60"
              src="/logo-mailhog.png"
              style="filter: brightness(0) invert(1)"
              width="24"
            />
          </template>
        </VBtn>
        <VBtn density="comfortable" :href="`${appConfig.links.api}/_/`" icon target="_blank">
          <template #default>
            <img alt="Pocketbase logo" class="opacity-60" src="/logo-pocketbase.png" width="20" />
          </template>
        </VBtn>
        <VDivider class="my-4 mx-1" vertical />
      </template>

      <VBtn density="comfortable" :icon="themeIcon" @click="toggleTheme" />
      <VTooltip text="Help">
        <template #activator="{ props: activatorProps }">
          <VBtn
            v-bind="activatorProps"
            density="comfortable"
            :icon="appIcons.mdiHelp"
            @click="helpDialog.show"
          />
        </template>
      </VTooltip>
      <template v-if="accountStore.account">
        <VMenu>
          <template #activator="{ props }">
            <VBtn v-bind="props" class="ml-4" icon>
              <VAvatar color="white">
                {{ accountStore.account.name.charAt(0).toUpperCase() }}
              </VAvatar>
            </VBtn>
          </template>
          <VList class="mt-1" min-width="200">
            <VListItem class="text-subtitle-2">{{ accountStore.account.name }}</VListItem>
            <VDivider />
            <VListItem to="/profile">Profile</VListItem>
            <template v-if="accountStore.isComposer">
              <VListItem to="/composer/submissions">Submissions</VListItem>
              <VListItem @click="reviewRubricDialog.show">Review Rubric</VListItem>
            </template>
            <VListItem @click="logoutConfirmDialog.show">Logout</VListItem>
          </VList>
        </VMenu>
      </template>
      <template v-else>
        <VBtn :active="false" to="/auth/apply" variant="outlined">Apply</VBtn>
        <VBtn :active="false" to="/auth/login" :variant="dark ? 'outlined' : 'elevated'">
          Login
        </VBtn>
      </template>
      <VBtn
        v-if="mobile && accountStore.isCollaborator"
        density="comfortable"
        :icon="mdiMenu"
        @click="emit('toggle-drawer')"
      />
    </template>

    <ConfirmDialog
      v-model="logoutConfirmDialog.open.value"
      :close-on-confirm="false"
      title="Logout?"
      @confirm="handleLogout"
    >
      Are you sure you want to logout?
    </ConfirmDialog>

    <AppHelpDialog v-model="helpDialog.open.value" />

    <ReviewRubricDialog v-model="reviewRubricDialog.open.value" mode="image" />
  </VAppBar>
</template>

<style lang="scss" scoped>
:deep(.v-toolbar__append) {
  gap: spacing(2);
}

:deep(.v-app-bar-title > div) {
  display: flex;
  align-items: center;
}
</style>
