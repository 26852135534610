import { keepPreviousData, useQuery } from "@tanstack/vue-query";

import { pb, pbCollectionNames } from "#api/pocketbase";
import type { PaginationInputRefs } from "#api/types";
import { getPaginationInputArgs } from "#api/utils";
import type { Season } from "./season.types";

// NOTE: Query keys used for invalidation should require all arguments, as 'undefined' in query key
//         prevents invalidating any queries with similar key but that do provide args for that argument!
export const seasonKeys = {
  all: ["seasons/all"],
  allPaginated: (args: PaginationInputRefs) => [...seasonKeys.all, args],
  current: ["seasons/current"],
} as const;

export const useSeasonsQuery = (args: PaginationInputRefs) => {
  return useQuery({
    placeholderData: keepPreviousData,
    queryKey: seasonKeys.allPaginated(args),
    queryFn: async () => {
      return pb
        .collection(pbCollectionNames.seasons)
        .getList<Season>(...getPaginationInputArgs(args), {
          sort: "-submissionsEnd",
        });
    },
  });
};

export const useCurrentSeasonQuery = () => {
  return useQuery({
    placeholderData: keepPreviousData,
    queryKey: seasonKeys.current,
    queryFn: async () => {
      return pb.send<Season | null>("/api/season/current", {});
    },
  });
};
