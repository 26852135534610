import {
  mdiPlus as mdiAdd,
  mdiCancel,
  mdiFileDocumentRefresh as mdiChangesRequested,
  mdiCheck,
  mdiCheckCircle,
  mdiClose,
  mdiCloseCircle,
  mdiDelete,
  mdiDownload,
  mdiPencil as mdiEdit,
  mdiCircleOutline as mdiEmptyCircle,
  mdiAlertCircle as mdiError,
  mdiPaperclip as mdiFile,
  mdiUpload as mdiFileUpload,
  mdiFilter,
  mdiHelpCircle as mdiHelp,
  mdiDotsHorizontal as mdiOverflowMenu,
  mdiCurrencyUsd as mdiPayment,
  mdiCurrencyUsdOff as mdiPaymentOff,
  mdiTimerSand as mdiPending,
  mdiRefresh,
  mdiMagnify as mdiSearch,
  mdiDecagram as mdiTierBadge,
  mdiMagnify as mdiView,
} from "@mdi/js";

// TODO: Extract to a sensible location
export const appIcons = {
  mdiAdd,
  mdiCancel,
  mdiCheck,
  mdiCheckCircle,
  mdiClose,
  mdiCloseCircle,
  mdiChangesRequested,
  mdiDelete,
  mdiDownload,
  mdiEdit,
  mdiEmptyCircle,
  mdiError,
  mdiFile,
  mdiFileUpload,
  mdiFilter,
  mdiHelp,
  mdiOverflowMenu,
  mdiPayment,
  mdiPaymentOff,
  mdiRefresh,
  mdiPending,
  mdiSearch,
  mdiTierBadge,
  mdiView,
};
