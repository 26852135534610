import type {
  LocationQuery,
  RouteLocationNormalized,
  RouteLocationRaw,
  RouteParams,
} from "vue-router";

import { pb } from "#api/pocketbase";
import type { AccountType } from "#modules/account/account.types";
import { mapRecordToAccount } from "#modules/account/account.utils";
import { router } from "./router";

/** Map of account types to URLs (for redirecting from shared URls to type-specific URL) */
type AccountTypeUrlMap = Record<AccountType, string>;

/**
 * Get URL for account type (used when redirecting by type from shared URLs)
 *
 * NOTE: Redirects unauthenticated requests to login
 *
 * @example
 * {
 *    path: "/profile",
 *    name: "sharedRoute",
 *    redirect: () => getAccountTypeUrl({
 *      composer: "/composer/profile",
 *      collaborator: "/collaborator/profile"
 *    })
 * }
 */
export const getAccountTypeUrl = (urlMap: AccountTypeUrlMap): string => {
  if (!pb.authStore.model || !pb.authStore.isValid) {
    return "/auth/login";
  }

  const authModel = mapRecordToAccount(pb.authStore.model!);
  return urlMap[authModel.type];
};

/** Get a redirect target from URL */
export const getUrlRedirect = (route: RouteLocationNormalized): string | null => {
  const { redirect } = route.query;
  return redirect && typeof redirect == "string" ? redirect : null;
};

/** Get a URL param (single value) */
export const getUrlParam = (params: RouteParams, key: string): string | null => {
  const value = params[key] ?? null;
  return Array.isArray(value) ? value[0] : value;
};

/**
 * Handle a URL redirect (manual replacement)
 *
 * @returns Whether URL was redirected
 */
export const handleUrlRedirect = (route: RouteLocationNormalized): boolean => {
  const redirect = getUrlRedirect(route);
  if (redirect) {
    router.replace(redirect);
    return true;
  }
  return false;
};

/**
 * Parse values from query params
 *
 * NOTE: Parses comma-separated strings into an array (typically IDs).
 *
 * @param   query         - URL query params
 * @param   defaultValues - Default search values (drives parsing)
 * @returns Parsed search values
 */
export const parseQueryParams = <T extends Record<string, string | string[]>>(
  query: LocationQuery,
  defaultValues: T,
): T => {
  return Object.keys(defaultValues).reduce(
    (accum, key) => {
      const defaultValue = defaultValues[key];
      let urlValue = query[key];
      if (!urlValue || !urlValue.length) {
        return accum;
      }

      const arrayType = Array.isArray(defaultValue);
      if (arrayType) {
        // Arrays may be represented in URL array format or as a comma-separated string
        urlValue = Array.isArray(urlValue) ? urlValue : urlValue.split(",").filter((x) => x);
      } else {
        // Use first element from arrays provided in place of expected strings
        urlValue = Array.isArray(urlValue) ? urlValue[0] : urlValue;
      }

      return { ...accum, [key]: urlValue };
    },
    { ...defaultValues },
  );
};

/** Route access check results */
interface RouteAccessValidation {
  /** Optional redirect for invalid routes (will cancel navigation if invalid and empty) */
  redirect?: RouteLocationRaw;
  /** Whether route access is valid */
  valid: boolean;
}

/**
 * Validate whether user should be able to access target route (provides redirect if not)
 *
 * Target routes are checked through a combination of auth flags in the target route's `meta`
 *   field, as well as matched parent's `meta` fields.
 *
 * Access checks performed while user auth check is still pending will not redirect as may be expected;
 *   rather, these checks must be performed again in the root App immediately after auth check finishes!
 *
 * @param   target - Target route
 * @returns Route validation response (invalid without redirect indicates cancelling navigation).
 */
export const validateRouteAccess = (target: RouteLocationNormalized): RouteAccessValidation => {
  // Pocketbase immediately determines auth validity on load (synchronous)
  const authenticated = pb.authStore.isValid;
  const authModel = pb.authStore.model ? mapRecordToAccount(pb.authStore.model) : null;

  // Some routes require authentication/verification (all matching routes must be checked)
  const requiresAuth = target.matched.some((r) => r.meta?.requiresAuth);
  const requiredUserType = target.matched.find((r) => r.meta?.requiresUserType)?.meta
    .requiresUserType;

  const getValidationReturn = (redirect: RouteLocationRaw | undefined, valid?: boolean) => ({
    redirect,
    // Route access validity defaults to whether redirect is specified (but can be overridden)
    valid: valid ?? !redirect,
  });

  // Requiring verification/user type implicitly requires authentication
  if (requiresAuth || requiredUserType) {
    if (!authenticated) {
      // Redirect to login while retaining the target route for post-auth redirect.
      const redirect = { path: "/auth/login", query: { redirectUrl: target.fullPath } };
      return getValidationReturn(redirect);
    }
  }

  if (requiredUserType) {
    if (authModel?.type !== requiredUserType) {
      // TODO: Handle on first route check!
      // Cancel navigation to routes requiring a different user type
      return getValidationReturn(undefined, false);
    }
  }

  // Some routes cannot be accessed when authenticated (all matching routes must be checked)
  const requiresNoAuth = target.matched.some((r) => r.meta?.requiresNoAuth);
  if (requiresNoAuth && authenticated) {
    // Cancel navigation to invalid unauthenticated routes when authenticated
    return getValidationReturn(undefined, false);
  }

  return getValidationReturn(undefined, true);
};
