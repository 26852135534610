import type { AuthModel } from "pocketbase";

import { pbCollectionNames } from "#api/pocketbase";
import { pick } from "#utilities/manipulators";
import type { AccountBase, AccountType } from "./account.types";

/** Map of Pocketbase collection names to web app type values */
export const accountAppTypeMap: Record<string, AccountType> = {
  [pbCollectionNames.collaborators]: "collaborator",
  [pbCollectionNames.composers]: "composer",
};

/** Map of web app type values to Pocketbase collection names */
export const accountPocketbaseTypeMap: Record<AccountType, string> = {
  collaborator: pbCollectionNames.collaborators,
  composer: pbCollectionNames.composers,
};

export const mapRecordToAccount = (record: NonNullable<AuthModel>): AccountBase => ({
  ...pick(record, ["betaFlags", "created", "email", "id"]),
  name: record.name ?? "N/A",
  type: accountAppTypeMap[record.collectionName],
  verified: !!record.verified,
});

export const getInitials = (name: string | undefined) => {
  return name
    ?.split(" ")
    .slice(0, 2)
    .reduce((accum, part) => accum + (part.replace(/[^a-z]/gi, "")[0] ?? ""), "");
};
