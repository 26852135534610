<script lang="ts" setup>
import {
  ReviewRubricGrid,
  ReviewRubricImage,
  ReviewRubricList,
} from "#components/data/review/ReviewRubric";
import reviewRubric from "#modules/review/data/review-rubric-v1.json";
import type { ReviewRubric as ReviewRubricType } from "#modules/review/review-rubric.types";

type ReviewRubricDisplay = "image" | "grid" | "list";

export interface ReviewRubricDialogProps {
  mode?: ReviewRubricDisplay;
}

withDefaults(defineProps<ReviewRubricDialogProps>(), {
  mode: "image",
});

const openModel = defineModel<boolean>();
</script>

<template>
  <VDialog v-model="openModel" max-width="800" v-bind="$attrs">
    <VCard class="w-fill">
      <VCardTitle>Review Rubric</VCardTitle>
      <VCardText class="py-0 pb-1 px-4 overflow-y-auto">
        <div class="mb-2">
          All submissions will be reviewed with the following rubric. Each review element will
          receive a score based on how it fulfills the listed criteria, before all elements are
          totalled to determine a submission's overall score.
        </div>
        <ReviewRubricGrid
          v-if="mode === 'grid'"
          :rubric="reviewRubric as unknown as ReviewRubricType"
        />
        <ReviewRubricList
          v-else-if="mode === 'list'"
          :rubric="reviewRubric as unknown as ReviewRubricType"
        />
        <ReviewRubricImage v-else image="/review_rubric.png" />
      </VCardText>
      <VCardActions>
        <ActionBar right>
          <VBtn @click="openModel = false">Close</VBtn>
        </ActionBar>
      </VCardActions>
    </VCard>
  </VDialog>
</template>
