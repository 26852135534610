<script lang="ts" setup>
// NOTE: Initial dynamic approach for review rubric, resulting in a undesirable output on small screens...
//         Using CSS Grid unfortunately is not intended for tabular layouts, particularly not when some rows
//         may have different lengths...
//       Additionally, while using a static image requires updates whenever the rubric changes, it renders
//         and scales consistenly across all sizes. However, using tables/grids causes severe issues due
//         to the lack of proper scaling support in CSS (ie. font size, spacing, ...everything, etc).

import { computed } from "vue";

import type { ReviewRubric } from "#modules/review/review-rubric.types";

interface ReviewRubricGridProps {
  rubric: ReviewRubric;
}

const props = withDefaults(defineProps<ReviewRubricGridProps>(), {});

// TODO: See about using plain HTML table (almost seems more stable at this point)
/** Using CSS Grid requires calculating the max columns to avoid invalid grid overflows... */
const maxColumns = computed(() =>
  props.rubric.sections.reduce(
    (accum, section) => (section.items.length > accum ? section.items.length : accum),
    0,
  ),
);
</script>

<template>
  <table class="rubric">
    <tr v-for="section in rubric.sections" :key="section.name" class="rubric-section">
      <td class="rubric-item rubric-section-name">
        {{ section.name }}
      </td>
      <td v-for="(item, idx) in section.items" :key="idx" class="rubric-item">
        <template v-if="item">
          {{ item.description }}
          <span class="font-weight-bold">({{ item.weight }})</span>
        </template>
      </td>
    </tr>
  </table>
</template>

<style lang="scss" scoped>
.rubric {
  // Using CSS Grid on a table with 'display: contents' unfortunately does not handle collapsing borders;
  //   instead, a workaround is to use an indentical grid gap and outline.
  // TODO: Investigate simply using an HTML table (seems far safer...)
  --border-width: 1px;

  display: grid;
  grid-template-columns: minmax(120px, auto) repeat(v-bind(maxColumns), minmax(0, 1fr));
  grid-template-rows: auto;
  border-collapse: collapse;
  grid-gap: var(--border-width);
  font-size: 0.7rem;
  margin-bottom: var(--border-width);
}

.rubric-section {
  display: contents;
}

.rubric-section-name {
  font-weight: 700;
}

.rubric-item {
  padding: 3px 4px;
  outline: var(--border-width) solid black;
}
</style>
