import { type RouteRecordRaw, RouterView } from "vue-router";

import { pb } from "#api/pocketbase";
import { mapRecordToAccount } from "#modules/account/account.utils";
import { getAccountTypeUrl } from "./utilities";

// NOTE: Utilize route code-splitting where appropriate/beneficial (Vue router recommendation)
// component: () => import("../views/AboutView.vue"),

/*
 * There are several types of route protection:
 *   - requiresAuth     - Route is unaccessible when not authenticated
 *   - requiresNoAuth   - Route is unaccessible when authenticated
 *   - requiresUserType - Router is unaccessible with incorrect user type
 */

/** Routes that require the user to be unauthenticated */
const unauthenticatedRoutes: RouteRecordRaw[] = [
  {
    path: "/",
    name: "dashboardNoAuth",
    component: () => import("#views/index/index.vue"),
    meta: { requiresNoAuth: true },
    beforeEnter: () => {
      // TODO: Decide if there will be an unauthenticated "home/welcome" page or not
      const authenticated = pb.authStore.isValid;
      if (!authenticated) {
        return { path: "/auth/login" };
      }
    },
  },
  {
    path: "/auth",
    name: "authRouter",
    component: () => import("#views/auth/AuthRouter.vue"),
    redirect: "/auth/login",
    children: [
      {
        path: "apply",
        name: "authApply",
        component: () => import("#views/auth/Apply/index.vue"),
        meta: { requiresNoAuth: true },
      },
      {
        path: "login",
        name: "authLogin",
        component: () => import("#views/auth/Login/index.vue"),
        meta: { requiresNoAuth: true },
      },
      // Logout route should always be accessible regardless of authentication status
      {
        path: "logout",
        name: "authLogout",
        component: () => import("#views/auth/Logout/index.vue"),
      },
      {
        path: "password/forgot",
        name: "authPasswordForget",
        component: () => import("#views/auth/PasswordForget/index.vue"),
        meta: { requiresNoAuth: true },
      },
      {
        path: "password/reset",
        name: "authPasswordReset",
        component: () => import("#views/auth/PasswordReset/index.vue"),
        meta: { requiresNoAuth: true },
      },
    ],
  },
];

/** Routes that require the user to be authenticated */
const authenticatedRoutes: RouteRecordRaw[] = [
  // Dashboard route redirects to root route of user type
  {
    path: "/dashboard",
    name: "authDashboard",
    meta: { requiresAuth: true },
    redirect: () =>
      getAccountTypeUrl({
        collaborator: "/collaborator",
        composer: "/composer",
      }),
  },
  {
    path: "/profile",
    name: "authProfile",
    meta: { requiresAuth: true },
    component: () => {
      if (!pb.authStore.model) {
        return null;
      }

      const account = mapRecordToAccount(pb.authStore.model);
      if (account.type === "composer") {
        return import("#views/composer/Profile/index.vue");
      } else if (account.type === "collaborator") {
        return import("#views/collaborator/Profile/index.vue");
      }

      return null;
    },
  },
];

const composerRoutes: RouteRecordRaw[] = [
  {
    path: "/composer",
    name: "composerRouter",
    component: RouterView,
    meta: { requiresUserType: "composer" },
    children: [
      {
        path: "",
        name: "composerDashboard",
        redirect: "/composer/submissions",
      },
      {
        path: "submissions",
        component: RouterView,
        children: [
          {
            path: "",
            name: "composerSubmissions",
            component: () => import("#views/composer/Dashboard/index.vue"),
          },
          {
            path: "add",
            name: "composerSubmissionAdd",
            component: () => import("#views/composer/Submission/SubmissionForm/index.vue"),
          },
          {
            path: ":id",
            name: "composerSubmissionDetails",
            component: () => import("#views/composer/Submission/index.vue"),
          },
          {
            path: ":id/edit",
            name: "composerSubmissionEdit",
            component: () => import("#views/composer/Submission/SubmissionForm/index.vue"),
          },
        ],
      },
    ],
  },
];

const collaboratorRoutes: RouteRecordRaw[] = [
  {
    path: "/collaborator",
    name: "collaboratorRouter",
    component: () => import("#views/collaborator/CollaboratorRouter.vue"),
    meta: { requiresUserType: "collaborator" },
    children: [
      {
        path: "",
        name: "collaboratorDashboard",
        component: () => import("#views/collaborator/Dashboard/index.vue"),
      },
      {
        path: "composers",
        name: "collaboratorComposers",
        component: () => import("#views/collaborator/Composers/index.vue"),
      },
      {
        path: "composers/:id",
        name: "collaboratorComposerDetails",
        component: () => import("#views/collaborator/Composers/ComposerDetails/index.vue"),
      },
      {
        path: "team",
        name: "collaboratorTeam",
        component: () => import("#views/collaborator/Team/index.vue"),
      },
      {
        path: "seasons",
        name: "collaboratorSeasons",
        component: () => import("#views/collaborator/Seasons/index.vue"),
      },
      {
        path: "submissions",
        component: RouterView,
        children: [
          {
            path: "",
            name: "collaboratorSubmissions",
            component: () => import("#views/collaborator/Submissions/index.vue"),
          },
          {
            path: ":submissionId",
            component: RouterView,
            children: [
              {
                path: "",
                name: "collaboratorSubmissionDetails",
                component: () =>
                  import("#views/collaborator/Submissions/SubmissionDetails/index.vue"),
              },
              {
                path: "review",
                name: "collaboratorSubmissionReview",
                component: () =>
                  import("#views/collaborator/Submissions/SubmissionReview/index.vue"),
              },
              {
                path: "feedback",
                name: "collaboratorSubmissionFeedback",
                component: () =>
                  import("#views/collaborator/Submissions/SubmissionFeedback/index.vue"),
              },
            ],
          },
        ],
      },
    ],
  },
];

const routes: RouteRecordRaw[] = [
  ...unauthenticatedRoutes,
  ...authenticatedRoutes,
  ...collaboratorRoutes,
  ...composerRoutes,
  {
    path: "/:pathMatch(.*)*",
    name: "pageNotFound",
    component: () => import("#views/PageNotFound.vue"),
  },
];

export default routes;
