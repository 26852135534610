import type { TableSortByItem } from "#composables/use-table-sort";
import { pbCollectionExpansions } from "./pocketbase";
import type { PaginationInput, PaginationInputRefs, PaginationResponse } from "./types";

export const DEFAULT_PAGE_SIZE = 10;

/** Get pagination from potentially empty/invalid pagination values */
const getPaginationInput = (pagination: Partial<PaginationInput> | undefined): PaginationInput => {
  return {
    page: Math.max(pagination?.page ?? 1, 1),
    perPage: Math.max(pagination?.perPage ?? DEFAULT_PAGE_SIZE, 1),
  };
};

/** Get Pocketbase pagination `getList` arguments  */
export const getPaginationInputArgs = (
  pagination?: Partial<PaginationInputRefs>,
): [page: number, perPage: number] => {
  const safePagination = getPaginationInput({
    page: pagination?.page?.value,
    perPage: pagination?.perPage?.value,
  });
  return [safePagination.page, safePagination.perPage];
};

/** Get pagination from Pocketbase response  */
export const getPaginationResponse = (
  input: Partial<PaginationResponse> = {},
): PaginationResponse => ({
  page: input.page ?? 1,
  perPage: input.perPage ?? DEFAULT_PAGE_SIZE,
  totalItems: input.totalItems ?? 0,
  totalPages: input.totalPages ?? 0,
});

/** Get API sort string representation */
export const getApiSortString = (
  sortBy: TableSortByItem | TableSortByItem[],
  keyMap?: Record<string, string>,
): string => {
  const transformSortBy = (item: TableSortByItem): string => {
    const key = keyMap?.[item.key] ?? item.key;
    return `${item.order === "asc" ? "" : "-"}${key}`;
  };

  return Array.isArray(sortBy) ? sortBy.map(transformSortBy).join(",") : transformSortBy(sortBy);
};

/**
 * Join Pocketbase `filter` strings together with `&&`
 *
 * NOTE: Omits empty filters
 */
export const joinApiFilters = (...filters: (string | undefined | null)[]): string => {
  return filters.filter((f) => f && typeof f === "string").join(" && ");
};

/**
 * Join Pocketbase `sort` strings together with `,`.
 *
 * NOTE: Omits empty sort strings.
 */
export const joinApiSort = (...sorts: (string | undefined | null)[]): string => {
  // Ensure unique sort values (via Set deduplication)
  const keySet = new Set(sorts);
  return Array.from(keySet)
    .filter((f) => f && typeof f === "string")
    .join(",");
};

/**
 * Get Pocketbase `expand` string with collection-aware type safety
 *
 * NOTE: Primarily intended as a TS safety utility function (vs simply using a raw string)
 *
 * @example
 * { expand: getApiExpansions("submission", ["composer"]) }
 */
export const getApiExpansions = <T extends keyof typeof pbCollectionExpansions>(
  collection: T,
  expansions: (typeof pbCollectionExpansions)[T][number][],
) => {
  // @ts-expect-error Unfortunately TS cannot properly understand types here...
  return expansions.filter((e) => pbCollectionExpansions[collection].includes(e)).join(",");
};

/** Get typed Pocketbase `expand` string with collection-aware type safety */
export const getTypedApiExpansion = <T extends keyof typeof pbCollectionExpansions>(
  _collection: T,
  expansion: (typeof pbCollectionExpansions)[T][number],
) => expansion;
