<template>
  <ConfirmDialog
    v-bind="dialogProps"
    :model-value="open"
    :title="dialogData?.title ?? ''"
    @cancel="triggerCancel"
    @confirm="triggerConfirm"
  >
    {{ dialogData?.message }}
    <div v-if="dialogData?.items" class="mt-2">
      <span v-if="!Array.isArray(dialogData.items)" class="font-weight-medium">
        {{ dialogData.items }}
      </span>
      <ul v-else class="ml-4">
        <li v-for="(item, idx) in dialogData.items" :key="item + idx">
          {{ item }}
        </li>
      </ul>
    </div>
    <VAlert v-if="dialogData?.warning" class="mt-3 text-body-2" color="warning" density="compact">
      {{ dialogData.warning }}
    </VAlert>
  </ConfirmDialog>
</template>

<script lang="ts" setup>
import { useConfirmDialogLocal } from "#composables/use-confirm-dialog";
import ConfirmDialog from "../dialogs/ConfirmDialog.vue";

const {
  dialogState: dialogData,
  dialogProps,
  open,
  triggerCancel,
  triggerConfirm,
} = useConfirmDialogLocal();
</script>
