import { useField, useFieldError, useSubmitCount } from "vee-validate";
import { computed } from "vue";
import type { ComputedRef, Ref } from "vue";

/**
 * Get a field's error message (if any)
 *
 * NOTE: Only shows errors if field is touched and form has attempted submission!
 *
 * @param fieldName - Field name (for VeeValidate)
 * @returns Error message
 */
export const useFieldErrorMessage = (fieldName: Ref<string>): ComputedRef<string | undefined> => {
  const { errorMessage: rawError, meta } = useField(fieldName);

  const submitCount = useSubmitCount();
  // const fieldError = useFieldError(fieldName);
  // const hasError = computed(() => fieldError.value);
  // TODO: Validate that using 'rawError' (over 'fieldError') does not cause any issues; however,
  //         using 'fieldError' was causing some file field upload errors to persist after they
  //         had actually been resolved (while 'rawError' updated properly).
  const hasError = computed(() => !!rawError.value);
  const hasAttemptedSubmitted = computed(() => submitCount.value > 0);

  const error = computed(() => {
    // Only show errors if form has had at least one submit attempt
    if (!meta.touched || !hasError.value || !hasAttemptedSubmitted.value) return;
    if (typeof rawError.value === "string") return rawError.value;

    return "Unknown error";
  });

  return error;
};
