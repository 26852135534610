<script lang="ts" setup>
import { mdiEmail, mdiLink } from "@mdi/js";

import { appIcons } from "#composables/use-icons";

const modelValue = defineModel<boolean>({ required: true });

const helpSections = [
  {
    title: "Cantate Help",
    text: "For general inquiries about Cantate or the overall submission process, please contact Cantate directly.",
    link: "https://www.cantatedomino.net",
  },
  {
    title: "Portal Help",
    text: "For help with portal-related issues, including account or submission issues, please contact the developer for support.",
    link: "mailto:kendall@kendallroth.ca?cc=cantatedominoensemble@gmail.com&subject=Cantate%20Portal%20support%20request",
  },
];
</script>

<template>
  <VDialog v-model="modelValue" max-width="500">
    <VCard>
      <VCardTitle>
        Help
        <VIcon color="secondary" :icon="appIcons.mdiHelp" size="24" />
      </VCardTitle>
      <VDivider />
      <VCardText class="pa-4">
        <template v-for="(section, sectionIdx) in helpSections" :key="sectionIdx">
          <div class="text-h4 mb-1">{{ section.title }}</div>
          <div class="text-body-2">{{ section.text }}</div>
          <VBtn
            v-if="section.link"
            class="mt-2"
            :href="section.link"
            :prepend-icon="section.link.startsWith('mailto') ? mdiEmail : mdiLink"
            target="_blank"
            variant="tonal"
          >
            Contact
          </VBtn>
          <VDivider v-if="sectionIdx < helpSections.length - 1" class="my-4" />
        </template>
      </VCardText>
    </VCard>
  </VDialog>
</template>
