import type { AuthModel } from "pocketbase";

import { mapRecordToAccount } from "#modules/account/account.utils";
import type { AccountCollaborator } from "./collaborator.types";

export const mapRecordToCollaborator = (record: NonNullable<AuthModel>): AccountCollaborator => ({
  ...mapRecordToAccount(record),
  disabledAt: record.disabledAt,
  role: record.role,
  type: "collaborator",
});
