<script lang="ts" setup>
/**
 * Originally this component mapped props directly to styles using `v-bind`; however, that led
 *   to many dynamic style variable bindings (annoying). Instead, this component now maps the
 *   previous prop values (to avoid sweeping refactors) to the corresponding Vuetify classes.
 */

import { computed } from "vue";

type FlexAlignment =
  | "center"
  | "flex-start"
  | "flex-end"
  | "stretch"
  | "space-between"
  | "space-around";

export interface LayoutStackProps {
  alignItems?: FlexAlignment;
  alignSelf?: FlexAlignment;
  direction?: "row" | "column";
  flexGrow?: boolean;
  flexWrap?: boolean;
  /** Custom component type (ie. 'form') */
  is?: string;
  justifyContent?: FlexAlignment;
  /** Spacing between items (4px increments) */
  spacing?: number | string;
}

const props = withDefaults(defineProps<LayoutStackProps>(), {
  alignItems: "flex-start",
  alignSelf: undefined,
  direction: "column",
  flexGrow: false,
  flexWrap: false,
  is: "div",
  justifyContent: "flex-start",
  spacing: 2,
});

const vuetifyFlexAlignClasses: Partial<Record<FlexAlignment, string>> = {
  "flex-end": "align-end",
  "flex-start": "align-start",
  center: "align-center",
  stretch: "align-stretch",
};
const vuetifyFlexAlignSelfClasses: Partial<Record<FlexAlignment, string>> = {
  "flex-start": "align-self-start",
  "flex-end": "align-self-end",
  center: "align-self-center",
  stretch: "align-self-stretch",
};
const vuetifyFlexJustifyClasses: Partial<Record<FlexAlignment, string>> = {
  "flex-start": "justify-start",
  "flex-end": "justify-end",
  center: "justify-center",
  "space-around": "justify-space-around",
  "space-between": "justify-space-between",
};

const classes = computed(() => {
  const classList = ["d-flex"];
  classList.push(props.direction === "column" ? "flex-column" : "flex-row");
  classList.push(vuetifyFlexAlignClasses[props.alignItems] ?? "");
  classList.push(props.alignSelf ? (vuetifyFlexAlignSelfClasses[props.alignSelf] ?? "") : "");
  classList.push(vuetifyFlexJustifyClasses[props.justifyContent] ?? "");
  classList.push(props.spacing ? `ga-${props.spacing}` : "");
  classList.push(props.flexWrap ? "flex-wrap" : "");
  classList.push(props.flexGrow ? "flex-grow-1" : "");
  return classList;
});
</script>

<template>
  <component :is="is" class="d-flex" :class="classes">
    <slot />
  </component>
</template>
