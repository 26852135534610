import type { FormContext, GenericObject, Path } from "vee-validate";
import { type AnyObject, number, type TestConfig, type TestFunction } from "yup";

type FieldValues = GenericObject;

/**
 * Set a form server error (prevents successful submission indicator)
 *
 * TODO: Determine if this is atually valid for VeeValidate!
 *
 * Used to prevent a form from indicating successful submission when there was a caught/handled
 *   API error, which VeeValidate ignores and would otherwise indicate as a successful submission.
 */
export const setFormServerError = (form: FormContext<FieldValues>, error: unknown) => {
  // TODO: Get actual error message from error!
  form.setFieldError("root.server", "API error occured");
};

/**
 * Disable cancel buttons when form is submitting or empty/unsubmitted
 *
 * @param form           - Form state (potentially from injected reference)
 * @param allowWhenEmpty - Allow cancelling (reset) when empty/unsubmitted (useful in dialogs, etc)
 */
export const shouldDisableCancel = (form: FormContext<FieldValues>, allowWhenEmpty = false) => {
  const isSubmittingOrValidating = form.isSubmitting.value || form.isValidating.value;

  // Some locations may allow cancelling when empty/unsubmitted (ie. dialogs)
  if (allowWhenEmpty) return isSubmittingOrValidating;

  // Must include submit count to allow resetting field after invalid submission attempt
  return isSubmittingOrValidating || (!form.meta.value.dirty && !form.submitCount.value);
};

/** Disable submit buttons when form is submitting, validating, unchanged */
export const shouldDisableSubmit = (form: FormContext<FieldValues>) => {
  // TODO: Handle intial values loading state (if possible)
  return form.isSubmitting.value || form.isValidating.value || !form.meta.value.dirty;
};

/**
 * Support strongly typing VeeValidate form field names (when using in UI, etc)
 *
 * @param   name - Field name (will be strongly typed)
 * @returns Typed field name
 *
 * @example
 * const getTypedFormField = <T extends GenericObject>(name: Path<T>) => name;
 *
 * type FormSchema = Yup.InferType<{ ... }>;
 * const getFormField = getTypedFormField<FormSchema>;
 *
 * return <Input name={getFormField("strongly.typed")} />;
 */
export const getTypedFormField = <TValues extends GenericObject>(name: Path<TValues>) => name;

/** Yup `test` comparator function for maximum file size */
export const getYupFileValidatorFunc =
  (args: { fileTypes?: (string | RegExp)[]; maxSize?: number }): TestFunction<File> =>
  (file: File, ctx) => {
    if (args.maxSize && file.size > args.maxSize * 1024 * 1024) {
      return ctx.createError({ message: `Invalid file size (<${args.maxSize}MB)` });
    }
    if (args.fileTypes) {
      const validType = args.fileTypes.some((accept) =>
        typeof accept === "string" ? accept === file.type : accept.test(file.type),
      );
      if (!validType) {
        return ctx.createError({ message: "Invalid file type" });
      }
    }
    return true;
  };

/** Yup `test` function object argument for maximum file size */
export const getYupFileValidatorFuncObj = (
  args: Parameters<typeof getYupFileValidatorFunc>[0],
): TestConfig<File, AnyObject> => ({
  name: "fileValidation",
  test: getYupFileValidatorFunc(args),
});
