import { computed, type ComputedRef, reactive, type Ref, toRefs } from "vue";

import type { AccountCollaborator } from "#modules/collaborator/collaborator.types";
import type { AccountComposer } from "#modules/composer/composer.types";
import type { AccountBase, AccountType } from "./account.types";

interface AccountStoreState {
  account: AccountBase | null;
}

/**
 * Global reactive authenticated account store
 *
 * NOTE: Will not work with SSR as it will be shared across requests!
 */
const accountStore = reactive<AccountStoreState>({
  account: null,
});

interface AccountStore {
  /**
   * Authenticated account (viewer)
   *
   * NOTE: Should remain unset after login and remain set after logout, as both trigger reloads that
   *         will properly set the auth state after the reload (this avoids flickers before reload).
   */
  account: Readonly<Ref<AccountBase | null>>;
  /** Whether viewer is authenticated (computed from `account`) */
  authenticated: ComputedRef<boolean>;
  /** Authenticated user (if composer) */
  composer: ComputedRef<AccountComposer | null>;
  /** Authenticated user (if collaborator) */
  collaborator: ComputedRef<AccountCollaborator | null>;
  isCollaborator: ComputedRef<boolean>;
  isComposer: ComputedRef<boolean>;
  /** Authenticated account type */
  type: ComputedRef<AccountType | null>;
  clearAccount: () => void;
  setAccount: (account: AccountBase) => void;
}

export const useAuthAccount = (): AccountStore => {
  const clearAccount = () => {
    accountStore.account = null;
  };

  const setAccount = (account: AccountBase) => {
    accountStore.account = account;
  };

  const storeStateRefs = toRefs(accountStore);

  return {
    // TODO: Consider enforcing as readonly (unless 'Readonly' func return type is sufficient?)
    account: storeStateRefs.account,
    authenticated: computed(() => !!accountStore.account),
    clearAccount,
    composer: computed(() =>
      accountStore.account?.type === "composer" ? (accountStore.account as AccountComposer) : null,
    ),
    collaborator: computed(() =>
      accountStore.account?.type === "collaborator"
        ? (accountStore.account as AccountCollaborator)
        : null,
    ),
    isCollaborator: computed(() => accountStore.account?.type === "collaborator"),
    isComposer: computed(() => accountStore.account?.type === "composer"),
    type: computed(() => accountStore.account?.type ?? null),
    setAccount,
  };
};
