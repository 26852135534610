import { useField, useIsSubmitting } from "vee-validate";
import { computed, type ToRefs } from "vue";

import { useFieldErrorMessage } from "./use-field-error-message";

export interface FieldInputProps {
  disabled?: boolean;
  hint?: string;
  label?: string;
  /** Form field name (ideally type-safe) */
  name: string;
}

/**
 * Form input utilities
 *
 * NOTE: Props **must** be passed with `toRefs(props)` (for reactivity)!
 */
export const useFormInput = (props: ToRefs<FieldInputProps>) => {
  const field = useField(props.name);

  const formSubmitting = useIsSubmitting();
  const disabled = computed(() => props.disabled?.value || formSubmitting.value);

  const errorMessage = useFieldErrorMessage(props.name);

  return {
    disabled,
    error: errorMessage,
    field,
    nameRef: props.name,
    labelRef: props.label,
  };
};
