import { createRouter, createWebHistory } from "vue-router";

import routes from "./routes";
import { validateRouteAccess } from "./utilities";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

/** Whether route change occurred as result of initial page load */
let initialPageLoad = true;

// Routing guards are primarily configured for protecting routes based on authentication
router.beforeEach((to) => {
  const wasInitialPageLoad = initialPageLoad;
  initialPageLoad = false;

  const routeValidation = validateRouteAccess(to);

  // Route navigation is either redirected or cancelled if target route validation fails
  if (!routeValidation.valid) {
    // Handle edge case where initial route is requested with invalid auth (from route `meta`),
    //   which will only occur when authenticated and requesting unauthenticated route, since
    //   the inverse case is already handled (with login redirect) when validating route access.
    const redirectFallback = wasInitialPageLoad ? "/dashboard" : false;
    return routeValidation.redirect ?? redirectFallback;
  }
});

export { router };
