import { keepPreviousData, useQuery } from "@tanstack/vue-query";
import type { Ref } from "vue";

import { pb, pbCollectionNames } from "#api/pocketbase";
import type { Content } from "./content.types";

// NOTE: Query keys used for invalidation should require all arguments, as 'undefined' in query key
//         prevents invalidating any queries with similar key but that do provide args for that argument!
export const contentKeys = {
  all: ["content"],
  byCode: (code: Ref<string>) => [...contentKeys.all, code],
} as const;

export const getContentByCode = (code: string) => {
  return pb.collection(pbCollectionNames.content).getFirstListItem<Content>(`code = '${code}'`);
};

export const useContentQuery = (code: Ref<string>) => {
  return useQuery({
    placeholderData: keepPreviousData,
    queryKey: contentKeys.byCode(code),
    queryFn: async () => {
      return getContentByCode(code.value);
    },
  });
};
