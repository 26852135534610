<script lang="ts" setup>
import { useDisplay } from "vuetify";

interface ActionBarProps {
  /** Whether actions are left-aligned */
  left?: boolean;
  /** Whether actions are right-aligned */
  right?: boolean;
  /** Spacing in left/right action bars */
  spacing?: number;
}

withDefaults(defineProps<ActionBarProps>(), {
  left: false,
  right: false,
  spacing: 2,
});

const { mobile } = useDisplay();
</script>

<template>
  <LayoutStack
    :align-items="mobile ? undefined : 'center'"
    class="action-bar w-100"
    :direction="mobile ? 'column' : 'row'"
  >
    <LayoutStack
      v-if="$slots.left || left"
      align-items="center"
      direction="row"
      flex-wrap
      :spacing="spacing"
    >
      <slot v-if="$slots.left" name="left" />
      <template v-else-if="left">
        <slot />
      </template>
    </LayoutStack>
    <LayoutStack
      v-if="$slots.right || right"
      align-items="center"
      class="ml-auto"
      direction="row"
      flex-wrap
      :spacing="spacing"
    >
      <slot v-if="$slots.right" name="right" />
      <template v-else-if="right">
        <slot />
      </template>
    </LayoutStack>
  </LayoutStack>
</template>
