import { defineStore } from "pinia";
import { computed, type ComputedRef, ref } from "vue";

import { useAuthAccount } from "#composables/use-auth-account";
import type { FeatureFlag, FeatureFlagStatus } from "./app.types";

type CurrentFeatureFlags = "workflow-review" | "workflow-feedback";

export const useAppStore = defineStore("app", () => {
  const authAccount = useAuthAccount();

  const featureFlagList = ref<FeatureFlag[]>([]);

  // NOTE: Whenever an account's "betaFlags" value changes, it will take an extra page refresh
  //         to take effect, due to how Pocketbase loads auth model first from stored JWT
  //         and then from an auth token refresh request (which apparently doesn't trigger 'computed'?).

  const featureFlags = computed(
    (): Record<CurrentFeatureFlags, boolean> => ({
      "workflow-feedback": getFeatureFlagEnabled("workflow-feedback").value,
      "workflow-review": getFeatureFlagEnabled("workflow-review").value,
    }),
  );

  /** Get whether feature flag is enabled */
  const getFeatureFlagEnabled = (
    code: CurrentFeatureFlags,
    fallback: FeatureFlagStatus = "enabled",
  ): ComputedRef<boolean> =>
    // Use computed ref to retain reactivity (if used directly in component)
    computed(() => {
      const status = getFeatureFlagStatus(code, fallback);

      // Beta feature flags can only be tested by select users
      if (status.value === "beta") {
        if (!authAccount.account.value) return false;
        const accountBetaFlags = authAccount.account.value.betaFlags?.split(",") ?? [];
        return accountBetaFlags.includes(code);
      }

      return status.value === "enabled";
    });

  /** Get feature flag status (with fallback if missing) */
  const getFeatureFlagStatus = (
    code: CurrentFeatureFlags,
    fallback: FeatureFlagStatus = "enabled",
  ): ComputedRef<FeatureFlagStatus> =>
    // Use computed ref to retain reactivity (if used directly in component)
    computed(() => {
      return featureFlagList.value?.find((flag) => flag.code === code)?.status ?? fallback;
    });

  return {
    /** Current feature flags and whether they are enabled */
    featureFlags,
    featureFlagList,
  };
});
