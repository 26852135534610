<script lang="ts" setup>
interface TitleBarProps {
  class?: string;
  title?: string;
  titleClass?: string;
}

const props = withDefaults(defineProps<TitleBarProps>(), {
  class: undefined,
  title: undefined,
  titleClass: undefined,
});
</script>

<template>
  <div v-if="$slots.breadcrumbs" class="align-self-start mb-1 w-content">
    <slot name="breadcrumbs" />
  </div>
  <ActionBar :class="props.class">
    <template #left>
      <LayoutStack align-items="center" class="text-h2" :class="titleClass" direction="row">
        <slot name="title">{{ title }}</slot>
      </LayoutStack>
      <slot name="title-append" />
    </template>
    <template v-if="$slots.actions" #right>
      <slot name="actions" />
    </template>
  </ActionBar>
</template>
