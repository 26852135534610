/**
 * Picks specified keys from an object and returns a new object with only those keys.
 *
 * @see https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore#_pick
 */
export const pick = <T extends object, K extends keyof T>(object: T, keys: K[]): Pick<T, K> => {
  return keys.reduce(
    (obj, key) => {
      // eslint-disable-next-line no-prototype-builtins
      if (object && object.hasOwnProperty(key)) {
        obj[key] = object[key];
      }
      return obj;
    },
    {} as Pick<T, K>,
  );
};

/** Omits specified keys from an object and returns a new object without those keys. */
export const omit = <T extends object, K extends keyof T>(object: T, keys: K[]): Omit<T, K> => {
  const clonedObject = { ...object };
  return keys.reduce((obj, key) => {
    // eslint-disable-next-line no-prototype-builtins
    if (object && object.hasOwnProperty(key)) {
      delete obj[key];
    }
    return obj;
  }, clonedObject as T);
};
