import { keepPreviousData, useQuery } from "@tanstack/vue-query";

import { pb, pbCollectionNames } from "#api/pocketbase";
import type { FeatureFlag } from "./app.types";

// NOTE: Query keys used for invalidation should require all arguments, as 'undefined' in query key
//         prevents invalidating any queries with similar key but that do provide args for that argument!
export const appKeys = {
  featureFlags: ["featureFlags"],
  healthCheck: ["api", "healthCheck"],
} as const;

export const useApiHealthcheck = () => {
  return useQuery({
    placeholderData: keepPreviousData,
    queryKey: appKeys.healthCheck,
    queryFn: async () => {
      return pb.health.check();
    },
  });
};

export const useFeatureFlagsQuery = () => {
  return useQuery({
    placeholderData: keepPreviousData,
    queryKey: appKeys.featureFlags,
    queryFn: async () => {
      return pb.collection(pbCollectionNames.featureFlags).getFullList<FeatureFlag>();
    },
  });
};
