import type { ReviewSchema } from "#views/collaborator/Submissions/SubmissionReview/schema";
import type { ReviewRubricSection, ReviewRubricSectionItem } from "./review-rubric.types";

/** Get filtered list of rubric sections (omitting empty sections) */
export const getRubricSectionItems = (section: ReviewRubricSection): ReviewRubricSectionItem[] => {
  return section.items.filter((i) => !!i);
};

/** Get total rubric form score from list of sections */
export const getRubricFormScore = (rubricFormSections: ReviewSchema["rubric"]): number => {
  return rubricFormSections.reduce((accum, section) => accum + section.score || 0, 0);
};

/** Get rubric form values for storage */
export const getRubricFormValue = (
  rubricFormSections: ReviewSchema["rubric"],
): Record<string, number> => {
  return rubricFormSections.reduce(
    (accum, section) => ({ ...accum, [section.code]: section.score }),
    {},
  );
};
