<script lang="ts" setup>
import { computed, ref, toRef, toRefs, useSlots } from "vue";
import { VFileInput } from "vuetify/components";

import { type FieldInputProps, useFormInput } from "./use-form-input";

interface FileFieldProps extends FieldInputProps {
  accept?: string;
}

const props = withDefaults(defineProps<FileFieldProps>(), {
  accept: "image/png, image/jpeg",
  disabled: false,
  hint: "",
  label: undefined,
});

const inputRef = ref<HTMLInputElement>();

/** Trigger HTML input file picker */
const triggerInput = () => {
  inputRef.value?.click();
};

defineExpose({
  /** Trigger HTML input file picker */
  triggerInput,
});

const acceptRef = toRef(props, "accept");

const { disabled, error, field } = useFormInput(toRefs(props));
const { handleBlur, handleChange } = field;
const formValue = computed(() => field.value.value as unknown as File[]);

const handleFileClear = () => {
  handleChange([]);
};

const handleFileChange = (files: File | File[]) => {
  const fileList = Array.isArray(files) ? files : [files];
  // Prevent clearing files when "cancel" is pressed (can clear with dedicated "clear" button)
  if (!fileList) return;

  handleChange(fileList);
};

// NOTE: Odd workaround necessary for getting around TS error with `#[slot]` reference otherwise
const rawSlots = useSlots();
const slots = computed<VFileInput["$slots"]>(() => rawSlots);
</script>

<template>
  <VFileInput
    v-bind="$attrs"
    ref="inputRef"
    :accept="acceptRef"
    class="file-input"
    :disabled="disabled"
    :error="!!error"
    :hint="error ?? hint"
    :label="label"
    :model-value="formValue"
    @blur="handleBlur"
    @click:clear="handleFileClear"
    @update:model-value="handleFileChange"
  >
    <template v-for="(_, slot) in slots" #[slot]="slotData">
      <slot :name="slot" v-bind="slotData" />
    </template>
  </VFileInput>
</template>

<style lang="scss" scoped>
.file-input :deep(.v-field__input) {
  // Prevent long filenames from expanding
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
