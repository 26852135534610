<script lang="ts" setup>
import { mdiEye, mdiEyeOff } from "@mdi/js";
import { computed, ref, toRefs, useSlots } from "vue";
import { VTextField } from "vuetify/components";

import { type FieldInputProps, useFormInput } from "./use-form-input";

interface TextFieldProps extends FieldInputProps {
  type?: string;
}

const props = withDefaults(defineProps<TextFieldProps>(), {
  disabled: false,
  hint: "",
  label: undefined,
  type: "text",
});

const { disabled, error, field } = useFormInput(toRefs(props));
const { value: formValue, handleBlur, handleChange } = field;

const passwordHidden = ref(true);

const inputType = computed(() => {
  if (props.type !== "password") return props.type;
  return passwordHidden.value ? "password" : "text";
});
const inputIcon = computed(() =>
  props.type === "password" ? (passwordHidden.value ? mdiEyeOff : mdiEye) : undefined,
);

const toggleHidden = () => {
  passwordHidden.value = !passwordHidden.value;
};

// NOTE: Odd workaround necessary for getting around TS error with `#[slot]` reference otherwise
const rawSlots = useSlots();
const slots = computed<VTextField["$slots"]>(() => rawSlots);
</script>

<template>
  <VTextField
    v-bind="$attrs"
    :append-inner-icon="inputIcon"
    :disabled="disabled || props.disabled"
    :error="!!error"
    :hint="error ?? hint"
    :label="label"
    :model-value="formValue"
    :type="inputType"
    @blur="handleBlur"
    @click:append-inner="toggleHidden"
    @update:model-value="handleChange"
  >
    <template v-for="(_, slot) in slots" #[slot]="slotData">
      <slot :name="slot" v-bind="slotData" />
    </template>
  </VTextField>
</template>
