<script lang="ts" setup>
import { computed, toRefs, useSlots } from "vue";
import { VTextarea } from "vuetify/components";

import { type FieldInputProps, useFormInput } from "./use-form-input";

type TextareaFieldProps = FieldInputProps;

const props = withDefaults(defineProps<TextareaFieldProps>(), {
  disabled: false,
  hint: "",
  label: undefined,
});

const { disabled, error, field } = useFormInput(toRefs(props));
const { value: formValue, handleBlur, handleChange } = field;

const rawSlots = useSlots();
const slots = computed<VTextarea["$slots"]>(() => rawSlots);
</script>

<template>
  <VTextarea
    v-bind="$attrs"
    :disabled="disabled"
    :error="!!error"
    :hint="error ?? hint"
    :label="label"
    :model-value="formValue"
    @blur="handleBlur"
    @update:model-value="handleChange"
  >
    <template v-for="(_, slot) in slots" #[slot]="slotData">
      <slot :name="slot" v-bind="slotData" />
    </template>
  </VTextarea>
</template>
