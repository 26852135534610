import dayjs from "dayjs";

export const FORMAT_DATE_SHORT = "MMM D, YYYY";
export const FORMAT_DATE_READABLE = "MMMM D, YYYY";

export const formatDate = (date: string | Date | null | undefined, format = FORMAT_DATE_SHORT) => {
  if (!date) return "";

  return dayjs(date).format(format);
};
