<script lang="ts" setup>
import { computed, toRefs, useSlots } from "vue";
import { VSelect } from "vuetify/components";

import { type FieldInputProps, useFormInput } from "./use-form-input";

interface SelectFieldProps extends FieldInputProps {}

const props = withDefaults(defineProps<SelectFieldProps>(), {
  disabled: false,
  hint: "",
  label: undefined,
  type: "text",
});

const { disabled, error, field } = useFormInput(toRefs(props));
const { value: formValue, handleBlur, handleChange } = field;

// NOTE: Odd workaround necessary for getting around TS error with `#[slot]` reference otherwise
const rawSlots = useSlots();
const slots = computed<VSelect["$slots"]>(() => rawSlots as VSelect["$slots"]);
</script>

<template>
  <VSelect
    v-bind="$attrs"
    :disabled="disabled || props.disabled"
    :error="!!error"
    :hint="error ?? hint"
    :label="label"
    :model-value="formValue"
    @blur="handleBlur"
    @update:model-value="handleChange"
  >
    <template v-for="(_, slot) in slots" #[slot]="slotData">
      <slot :name="slot" v-bind="slotData" />
    </template>
  </VSelect>
</template>
