<script lang="ts" setup>
import { toRefs } from "vue";

import { type FieldInputProps, useFormInput } from "./use-form-input";

interface CheckboxFieldProps extends FieldInputProps {}

const props = withDefaults(defineProps<CheckboxFieldProps>(), {
  disabled: false,
  hint: "",
  label: undefined,
  type: "text",
});

const { disabled, error, field } = useFormInput(toRefs(props));
const { value: formValue, handleBlur, handleChange } = field;
</script>

<template>
  <VCheckbox
    v-bind="$attrs"
    :disabled="disabled || props.disabled"
    :error="!!error"
    :hint="error ?? hint"
    :label="label"
    :model-value="formValue"
    @blur="handleBlur"
    @update:model-value="handleChange"
  />
</template>
