import type { AuthModel } from "pocketbase";

import { mapRecordToAccount } from "#modules/account/account.utils";
import type { AccountComposer } from "./composer.types";

export const mapRecordToComposer = (record: NonNullable<AuthModel>): AccountComposer => ({
  ...mapRecordToAccount(record),
  acceptedAt: record.acceptedAt,
  rejectedAt: record.rejectedAt,
  biography: record.biography,
  church: record.church,
  disabledAt: record.disabledAt,
  location: record.location,
  phone: record.phone,
  photo: record.photo,
  status: record.status,
  type: "composer",
});
