import "vuetify/styles";
import { createVuetify, type ThemeDefinition } from "vuetify";
import { md3 } from "vuetify/blueprints";
import { aliases, mdi as mdiIcons } from "vuetify/iconsets/mdi-svg";

import { appConfig } from "#config/app";

// Theme colors generated at: https://m3.material.io/theme-builder

const darkTheme: ThemeDefinition = {
  dark: true,
  colors: {
    primary: "#4dd9e6", // original
    "on-primary": "#00363b", // original
    secondary: "#b1cbce", // original
    "on-secondary": "#1c3437", // original
    tertiary: "#b8c6ea", // original
    "on-tertiary": "#690005", // original
    background: "#191c1d", // original
    "on-background": "#e0e3e3",
    error: "#ffb4ab", // original
    "on-error": "#690005", // original
    // surface: "#191c1d", // original
    surface: "#2a2f31",
    // "on-surface": "#e0e3e3", // original
    "on-surface": "#e2e5e5",
  },
};

const lightTheme: ThemeDefinition = {
  dark: false,
  colors: {
    primary: "#006971",
    "on-primary": "#ffffff",
    secondary: "#4a6365",
    "on-secondary": "#ffffff",
    tertiary: "#505e7d",
    "on-tertiary": "#ffffff",
    background: "#f6fcfc",
    "on-background": "#191c1d",
    error: "#ba1a1a",
    "on-error": "#ffffff",
    surface: "#fefefe",
    // surface: "#fafdfd", // original
    "on-surface": "#060707",
    // "on-surface": "#191c1d", // original
  },
};

export const vuetifyPlugin = createVuetify({
  blueprint: md3,
  defaults: {
    VAlert: {
      variant: "tonal",
    },
    VCheckbox: {
      color: "primary",
      persistentHint: true,
    },
    VDataTable: {
      itemsPerPage: appConfig.development ? 10 : 50,
      itemsPerPageOptions: appConfig.development ? [1, 2, 5, 10, 25, 50, 100] : undefined,
    },
    VDateInput: {
      color: "primary",
      persistentHint: true,
    },
    VDataTableServer: {
      itemsPerPage: appConfig.development ? 10 : 50,
      itemsPerPageOptions: appConfig.development ? [1, 2, 5, 10, 25, 50, 100] : undefined,
    },
    VFileInput: {
      persistentHint: true,
    },
    VPagination: {
      density: "compact",
      totalVisible: 5,
    },
    VProgressCircular: {
      color: "primary",
    },
    VSelect: {
      color: "primary",
      persistentHint: true,
    },
    VSwitch: {
      color: "primary",
      inset: true,
      persistentHint: true,
      style: "--v-input-control-height: unset;",
    },
    VTextField: {
      color: "primary",
      persistentHint: true,
    },
    VTextarea: {
      color: "primary",
      persistentHint: true,
    },
    VTooltip: {
      location: "bottom center",
      openDelay: 250,
    },
  },
  display: {
    mobileBreakpoint: "md",
  },
  icons: {
    defaultSet: "mdi",
    aliases,
    sets: { mdi: mdiIcons },
  },
  theme: {
    defaultTheme: "light",
    themes: {
      dark: darkTheme,
      light: lightTheme,
    },
    variations: {
      colors: ["primary", "secondary", "tertiary", "error", "warning"],
      lighten: 2,
      darken: 2,
    },
  },
  // TODO: Consider changing if SSR is ever supported (or fixed) at Nuxt project level
  ssr: false,
});
