import dayjs from "dayjs";
import { defineStore } from "pinia";
import { computed, ref } from "vue";

import { type Season } from "./season.types";

export const useSeasonStore = defineStore("season", () => {
  const currentSeason = ref<Season | undefined>();

  const acceptingSubmissions = computed<boolean>(() => {
    if (!currentSeason.value) return false;

    const submissionsStart = dayjs(currentSeason.value.submissionsStart);
    const submissionsEnd = dayjs(currentSeason.value.submissionsEnd);

    // Submissions are accepted on first/last days ('[]' shortcode)
    return dayjs().isBetween(submissionsStart, submissionsEnd, "day", "[]");
  });

  return {
    /** Whether submissions are currently being accepted (ie. is today within start/end of current season) */
    acceptingSubmissions,
    currentSeason,
  };
});
