<script lang="ts" setup>
import { mdiWifiOff } from "@mdi/js";
import { setUser as setSentryUser } from "@sentry/vue";
import { useQueryClient } from "@tanstack/vue-query";
import { VueQueryDevtools } from "@tanstack/vue-query-devtools";
import { useToggle } from "@vueuse/core";
import { computed, onMounted, reactive, ref, watch } from "vue";
import { useRoute } from "vue-router";

import { pb } from "#api/pocketbase";
import LayoutStack from "#components/layout/LayoutStack.vue";
import TheAppBar from "#components/single/TheAppBar.vue";
import TheAppConfirmDialog from "#components/single/TheAppConfirmDialog.vue";
import TheAppDebug from "#components/single/TheAppDebug.vue";
import TheAppDrawer from "#components/single/TheAppDrawer.vue";
import TheAppSnackbar from "#components/single/TheAppSnackbar.vue";
import { useAuthAccount } from "#composables/use-auth-account";
import { appConfig } from "#config/app";
import { mapRecordToAccount } from "#modules/account/account.utils";
import { useApiHealthcheck, useFeatureFlagsQuery } from "#modules/app/app.queries";
import { useAppStore } from "#modules/app/app.store";
import { mapRecordToCollaborator } from "#modules/collaborator/collaborator.utils";
import { mapRecordToComposer } from "#modules/composer/composer.utils";
import { contentKeys, getContentByCode } from "#modules/content/content.queries";
import { useCurrentSeasonQuery } from "#modules/season/season.queries";
import { useSeasonStore } from "#modules/season/season.store";
import { FORMAT_DATE_READABLE, formatDate } from "#utilities/date";
import { getError } from "#utilities/error";
import { enDash } from "#utilities/text";

const route = useRoute();
const queryClient = useQueryClient();
const accountStore = reactive(useAuthAccount());

const [showMobileDrawer, toggleMobileDrawer] = useToggle(false);

const loadingStatus = ref<"loading" | "error" | "ready">("loading");

const { error: apiHealthError, isLoading: apiHealthLoading } = useApiHealthcheck();

const loading = computed(() => {
  return loadingStatus.value === "loading" || apiHealthLoading.value || featureFlagsLoading.value;
});

const appStore = useAppStore();
const seasonStore = useSeasonStore();

const { data: seasonData } = useCurrentSeasonQuery();
watch(seasonData, (newSeasonData) => {
  seasonStore.currentSeason = newSeasonData ?? undefined;
});

const { data: featureFlagsData, isLoading: featureFlagsLoading } = useFeatureFlagsQuery();
watch(featureFlagsData, (newFeatureFlags) => {
  appStore.featureFlagList = newFeatureFlags ?? [];
});

const seasonDisplay = computed(() => {
  if (!seasonStore.currentSeason) return "N/A";

  const { submissionsEnd, submissionsStart } = seasonStore.currentSeason;

  return seasonStore.currentSeason
    ? `${formatDate(submissionsStart, FORMAT_DATE_READABLE)} ${enDash} ${formatDate(submissionsEnd, FORMAT_DATE_READABLE)}`
    : "N/A";
});

onMounted(async () => {
  // Dev-specific workaround to race condition caused by conditional/async import of MSW.
  //   See 'main.ts' for more details/explanation.
  // NOTE: MSW must be ready before sending any further API requests (or they will fail)!
  if (appConfig.development && window.mswReady) {
    await window.mswReady;
  }

  // TODO: Load additional data

  checkAuthentication();

  // Only prefetch application terms when not authenticated
  if (!pb.authStore.isValid) {
    queryClient.prefetchQuery({
      queryKey: contentKeys.byCode(ref("applicationTerms")),
      queryFn: () => getContentByCode("applicationTerms"),
    });
  }

  loadingStatus.value = "ready";
});

const checkAuthentication = () => {
  // Ensure auth store is cleaned up if stored auth state is invalid
  if (!pb.authStore.isValid || !pb.authStore.model) {
    pb.authStore.clear();
    return;
  }

  // Initiate refresh token workflow if existing token is valid
  pb.collection(pb.authStore.model.collectionName)
    .authRefresh()
    .catch(() => {
      pb.authStore.clear();
    });
};

/**
 * Previous auth store state (for comparisons with current state)
 *
 * By comparing previous state with current auth store update value, actions can be taken to reflect
 *   the performed action. This can work with both "immediate" or "lazy" auth store change handler,
 *   since the auth store is synchronously set upon app load (vs having to send request to API first).
 */
const prevModel = ref(pb.authStore.model);
pb.authStore.onChange(async (_token, model) => {
  const wasAuthenticated = !!prevModel.value;
  const nowAuthenticated = !!model;
  prevModel.value = model;

  if (appConfig.development) {
    console.debug("AuthStore.onChange", model, wasAuthenticated, nowAuthenticated);
  }

  // When user becomes authenticated (ie. via login), a reload should be triggered to ensure proper
  //   page state. The authenticated user will then be automatically detected/set upon load.
  if (!wasAuthenticated && nowAuthenticated) {
    const { redirectUrl } = route.query;
    const finalUrl = redirectUrl && typeof redirectUrl === "string" ? redirectUrl : "/dashboard";
    window.location.replace(finalUrl);
    return;
  }

  // When user was already authenticated and auth is updated, ensure authenticated user is updated.
  //   This will usually occur through token refresh, but may also occur on initial page load.
  if (wasAuthenticated && nowAuthenticated) {
    const accountBase = mapRecordToAccount(model);
    const account =
      accountBase.type === "composer" ? mapRecordToComposer(model) : mapRecordToCollaborator(model);
    accountStore.setAccount(account);
    setSentryUser({ email: account.email, id: account.id, type: account.type });
    return;
  }

  // When user becomes unauthenticated (ie. via logout), a reload should be triggered to ensure proper
  //   page state. The authenticated user should not be removed from cache (to avoid flickers), as it
  //   will no longer be present upon load.
  if (wasAuthenticated && !nowAuthenticated) {
    window.location.replace("/auth/login");
    return;
  }

  // When user was already unauthenticated and auth is cleared again, ensure auth store is cleared.
  if (!wasAuthenticated && !nowAuthenticated) {
    accountStore.clearAccount();
    setSentryUser(null);
    return;
  }
}, true);
</script>

<template>
  <VApp>
    <TheAppBar @toggle-drawer="toggleMobileDrawer" />
    <VSystemBar
      v-if="seasonStore.currentSeason"
      class="season-bar"
      :color="seasonStore.acceptingSubmissions ? 'primary-lighten-2' : 'warning-lighten-2'"
    >
      Accepting Submissions: {{ seasonDisplay }}
    </VSystemBar>

    <template v-if="loading">
      <VProgressCircular class="ma-auto" indeterminate size="80" width="6" />
    </template>
    <template v-else-if="apiHealthError">
      <VMain>
        <LayoutStack align-items="center" class="w-100 h-100" justify-content="center">
          <VCard class="offline-card pa-6 w-full" color="error" variant="tonal">
            <VIcon class="d-block mx-auto" :icon="mdiWifiOff" size="48" />
            <VCardTitle>Unknown Error</VCardTitle>
            <VCardText>
              Oh no, it looks like there's an unexpected issue! Check back soon!
              <VAlert v-if="apiHealthError" class="mt-4" type="error">
                {{ getError(apiHealthError) }}
              </VAlert>
            </VCardText>
          </VCard>
        </LayoutStack>
      </VMain>
    </template>
    <template v-else>
      <!-- Drawer is only shown for collaborators currently -->
      <TheAppDrawer
        v-if="accountStore.authenticated && accountStore.isCollaborator"
        :show-on-mobile="showMobileDrawer"
        @toggle-drawer="toggleMobileDrawer"
      />

      <VMain>
        <RouterView />
      </VMain>
    </template>

    <TheAppConfirmDialog />
    <TheAppDebug />
    <TheAppSnackbar />

    <VueQueryDevtools v-if="!appConfig.production" />
  </VApp>
</template>

<style lang="scss" scoped>
.season-bar {
  justify-content: center;
  text-align: center;
}

.offline-card {
  margin: auto;
  max-width: 500px;
}
</style>
