import { keepPreviousData, useQuery } from "@tanstack/vue-query";

import { pb } from "#api/pocketbase";
import type { CollaboratorStats, ComposerStats, SubmissionsCurrentStats } from "./stats.types";

// NOTE: Query keys used for invalidation should require all arguments, as 'undefined' in query key
//         prevents invalidating any queries with similar key but that do provide args for that argument!
export const statKeys = {
  platformCollaborators: ["stats", "platform", "collaborators"],
  platformComposers: ["stats", "platform", "composers"],
  platformSubmissionsCurrent: ["stats", "platform", "submissions", "current"],
} as const;

export const usePlatformStatsCollaboratorsQuery = () => {
  return useQuery({
    placeholderData: keepPreviousData,
    queryKey: statKeys.platformCollaborators,
    queryFn: async () => {
      return pb.send<CollaboratorStats>("/api/stats/platform/collaborator", {});
    },
  });
};

export const usePlatformStatsComposersQuery = () => {
  return useQuery({
    placeholderData: keepPreviousData,
    queryKey: statKeys.platformComposers,
    queryFn: async () => {
      return pb.send<ComposerStats>("/api/stats/platform/composer", {});
    },
  });
};

export const usePlatformStatsSubmissionsCurrentQuery = () => {
  return useQuery({
    placeholderData: keepPreviousData,
    queryKey: statKeys.platformSubmissionsCurrent,
    queryFn: async () => {
      return pb.send<SubmissionsCurrentStats>("/api/stats/platform/submission/current", {});
    },
  });
};
