import { useStorage } from "@vueuse/core";
import { watch } from "vue";

/** Storage key prefix to avoid collisions with other apps */
export const STORAGE_KEY_PREFIX = "cantate-portal";

interface StorageStateOptions<T> {
  defaultValue: T;
  /** Storage key prefix override */
  prefix?: string;
  storage?: Storage;
  /**
   * Whether parsed value is valid or not (will update to `defaultValue`)
   *
   * NOTE: Composable's type-aware serialization may wrangle some invalid values,
   *         preventing `valid` check from working effectively (ie. booleans, etc).
   */
  valid?: (value: T) => boolean;
}

/**
 * Reactive `ref` used to access and modify browser storage
 *
 * NOTE: Wrapper for `useStorage` from `@vueuse/core`
 *
 * @source https://vueuse.org/core/useStorage
 */
export const useStorageState = <T>(key: string, options: StorageStateOptions<T>) => {
  const { defaultValue, storage: storageReference = localStorage, valid: checkValid } = options;

  /** Prefixed storage key */
  const storageKey = `${options.prefix ?? STORAGE_KEY_PREFIX}-${key}`;

  const rawStoredState = useStorage(storageKey, options.defaultValue, storageReference);

  watch(
    rawStoredState,
    (newValue) => {
      if (!checkValid || checkValid(newValue)) return;

      rawStoredState.value = defaultValue;
    },
    { immediate: true },
  );

  return rawStoredState;
};
