import type { Promisable } from "type-fest";
import { computed, type ComputedRef, ref } from "vue";

import type { ConfirmDialogProps } from "#components/dialogs/ConfirmDialog.vue";
import { pick } from "#utilities/manipulators";

/** Props passed as `dialogProps` for binding to Confirm Dialog */
type ConfirmDialogPropsObject = Pick<
  ConfirmDialogProps,
  "cancelText" | "confirmText" | "confirmColor" | "title" | "loading"
>;

interface ConfirmDialogConfig extends ConfirmDialogPropsObject {
  /**
   * List of item names to display under confirmation message
   *
   * Passing an array will display a bulleted list, while a flat string will display inline.
   *
   * TODO: Not yet implemented (requires design)!
   */
  items?: string | string[];
  /** Main dialog description/content */
  message?: string;
  /** Warning message (displayed under content) */
  warning?: string;
  /** Cancellation callback */
  onCancel?: () => void;
  /** Confirmation callback */
  onConfirm: () => Promisable<unknown>;
}

interface ConfirmDialogState extends ConfirmDialogConfig {
  /** Whether confirmation dialog is submitting */
  submitting: boolean;
}

/** App/global confirmation dialog composable */
interface ConfirmDialogGlobalResult {
  /** Close confirmation dialog */
  hide: () => void;
  /** Set confirm dialog submission status */
  setSubmitting: (submitting: boolean) => void;
  /** Show confirmation dialog */
  show: (args: ConfirmDialogConfig) => void;
}

/**
 * Local confirmation dialog composable (ie. within component)
 *
 * NOTE: Local confirmation dialog still uses global confirmation dialog state (since only one can be present at once);
 *         however, it is intended for use with custom confirm dialogs (hence exposing all data/hooks).
 */
interface ConfirmDialogLocalResult extends ConfirmDialogGlobalResult {
  /** Applicable dialog props (intended to be bound to `ConfirmDialog`) */
  dialogProps: ComputedRef<ConfirmDialogPropsObject | undefined>;
  /** All relevant dialog data (includes props) */
  dialogState: ComputedRef<ConfirmDialogConfig | undefined>;
  /** Whether confirmation dialog is open */
  open: ComputedRef<boolean>;

  /** Trigger cancellation workflow (ie. from dialog button) */
  triggerCancel: () => void;
  /** Trigger confirmation workflow (ie. from dialog button) */
  triggerConfirm: () => Promisable<void>;
}

const dialogStore = ref<ConfirmDialogState | undefined>();

/**
 * Use separate state for open state to allow cleaning up after dialog has finished closing,
 *  avoiding some "popping" that would otherwise occur as content was nullified during close.
 */
const open = ref(false);

/**
 * Local confirmation dialog composable for providing async confirmation dialogs (supports custom content, etc).
 *
 * NOTE: Requires implementing own `ConfirmDialog` within component; use `useConfirmDialog` where possible!
 */
export const useConfirmDialogLocal = (): ConfirmDialogLocalResult => {
  /** Internal cancel handler (do not expose!) */
  const handleCancel = () => {
    hideConfirm();
    dialogStore.value?.onCancel?.();
  };

  /** Internal confirmation handler (do not expose!) */
  const handleConfirm = async () => {
    if (!dialogStore.value) {
      return;
    }

    setSubmitting(true);
    try {
      await dialogStore.value.onConfirm();
      hideConfirm();
    } finally {
      // Ensure submitting state is reset, and any errors will be propagated naturally for something else to handle
      setSubmitting(false);
    }
  };

  /**
   * Close confirmation dialog
   *
   * NOTE: Should not call `onCancel` callback to avoid infinite loops!
   */
  const hideConfirm = () => {
    open.value = false;
    setSubmitting(false);
  };

  /** Set confirmation submission state */
  const setSubmitting = (submitting: boolean) => {
    if (dialogStore.value) {
      dialogStore.value.submitting = submitting;
    }
  };

  /** Show confirmation dialog */
  const showConfirm = (args: ConfirmDialogConfig) => {
    dialogStore.value = {
      ...args,
      submitting: false,
    };
    open.value = true;
  };

  const dialogProps = computed<ConfirmDialogPropsObject | undefined>(() => {
    if (!dialogStore.value) {
      return undefined;
    }

    return {
      ...pick(dialogStore.value, ["cancelText", "confirmColor", "confirmText", "title"]),
      loading: dialogStore.value.submitting,
    };
  });

  return {
    dialogProps,
    dialogState: computed(() => dialogStore.value),
    hide: hideConfirm,
    open: computed(() => open.value),
    setSubmitting,
    show: showConfirm,
    triggerCancel: handleCancel,
    triggerConfirm: handleConfirm,
  };
};

/**
 * App/global confirmation dialog composable for providing async confirmation dialogs
 *
 * NOTE: Uses global `TheAppConfirmDialog` component.
 */
export const useConfirmDialog = (): ConfirmDialogGlobalResult => {
  const { hide, setSubmitting, show } = useConfirmDialogLocal();

  return {
    hide,
    setSubmitting,
    show,
  };
};
