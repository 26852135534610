import { computed, onBeforeUnmount, ref } from "vue";
import type { ComputedRef, Ref } from "vue";

interface UseCountdown {
  countdown: Ref<number>;
  /** Whether countdown is active */
  active: ComputedRef<boolean>;
  /** Whether countdown is stopped/inactive */
  inactive: ComputedRef<boolean>;
  /**
   * Begin a second-based countdown
   *
   * @param seconds  - Countdown length
   * @param callback - Optional callback when finished
   */
  start: (seconds: number, callback?: () => void) => void;
}

/** Countdown interval utilities (operating in seconds) */
export const useCountdown = (): UseCountdown => {
  const countdown = ref(0);
  const countdownInterval = ref<number | undefined>();

  const countdownActive = computed(() => countdown.value > 0);
  const countdownInactive = computed(() => countdown.value <= 0);

  onBeforeUnmount(() => {
    clearInterval(countdownInterval.value);
  });

  const startCountdown = (seconds: number, callback?: () => void) => {
    clearInterval(countdownInterval.value);
    countdown.value = seconds;

    countdownInterval.value = window.setInterval(() => {
      countdown.value--;

      if (countdown.value <= 0) {
        callback?.();
        countdown.value = 0;
        clearInterval(countdownInterval.value);
      }
    }, 1000);
  };

  return {
    countdown,
    active: countdownActive,
    inactive: countdownInactive,
    start: startCountdown,
  };
};
