import type { App } from "vue";

import ConfirmDialog from "#components/dialogs/ConfirmDialog.vue";
import CheckboxField from "#components/form/CheckboxField.vue";
import FileField from "#components/form/FileField.vue";
import FormReset from "#components/form/FormReset.vue";
import FormSubmit from "#components/form/FormSubmit.vue";
import SelectField from "#components/form/SelectField.vue";
import TextField from "#components/form/TextField.vue";
import TextareaField from "#components/form/TextareaField.vue";
import ActionBar from "#components/layout/ActionBar.vue";
import AppPage from "#components/layout/AppPage.vue";
import LayoutStack from "#components/layout/LayoutStack.vue";
import TitleBar from "#components/layout/TitleBar.vue";

/**
 * Register globally available components
 *
 * NOTE: Global components should be defined in '@typings/shims/components.d.ts' in order to
 *         utilize editor type/prop completion.
 */
export const componentsPlugin = {
  install: (app: App) => {
    // Dialogs
    app.component("ConfirmDialog", ConfirmDialog);
    // Forms
    app.component("CheckboxField", CheckboxField);
    app.component("FileField", FileField);
    app.component("FormSubmit", FormSubmit);
    app.component("FormReset", FormReset);
    app.component("SelectField", SelectField);
    app.component("TextField", TextField);
    app.component("TextareaField", TextareaField);
    // Layout
    app.component("ActionBar", ActionBar);
    app.component("AppPage", AppPage);
    app.component("LayoutStack", LayoutStack);
    app.component("TitleBar", TitleBar);
  },
};
