import {
  mdiThemeLightDark as mdiAuto,
  mdiWeatherNight as mdiDark,
  mdiWeatherSunny as mdiLight,
} from "@mdi/js";
import { useColorMode, useCycleList } from "@vueuse/core";
import { computed, type ComputedRef, type Ref, watchEffect } from "vue";
import { type ThemeInstance, useTheme as useVuetifyTheme } from "vuetify";

import { STORAGE_KEY_PREFIX } from "./use-storage-state";

export enum AppThemes {
  AUTO = "auto",
  LIGHT = "light",
  DARK = "dark",
}
export type AppTheme = `${AppThemes}`;

export const themeIconMap: Record<AppThemes, string> = {
  [AppThemes.AUTO]: mdiAuto,
  [AppThemes.DARK]: mdiDark,
  [AppThemes.LIGHT]: mdiLight,
};

type VuetifyTheme = ThemeInstance["current"];

export const APP_THEME_STORAGE_KEY = "app-theme";

interface AppThemeComposable {
  /** App theme name (persisted, includes `auto`) */
  appTheme: Ref<AppTheme>;
  /** Whether current theme is a dark theme (for contextual changes, etc) */
  dark: ComputedRef<boolean>;
  /** Current theme icon */
  icon: ComputedRef<string>;
  /** Set the app theme */
  setTheme: (theme: AppTheme) => void;
  /** Toggle theme between light/dark */
  toggleTheme: () => void;
  /** Vuetify current theme */
  vuetify: VuetifyTheme;
}

// TODO: Consider replacing with custom solution to avoid some of these pitfalls
// https://github.com/kendallroth/starter-kit-vue/blob/main/src/composables/use-app-theme.ts
export const useAppTheme = (): AppThemeComposable => {
  const vuetifyTheme = useVuetifyTheme();

  /**
   * Color mode has several properties with slight differences:
   *  - `state` - Resolved color mode name (`auto` is resolved to `light`/`dark`)
   *  - `store` - Raw color mode value (including `auto`)
   *  - `value` - Allegedly equal to `state`, but has wrong types (includes `auto`)???
   *  - `system` - Browser light/dark preference
   */
  const colorMode = useColorMode({
    storageKey: `${STORAGE_KEY_PREFIX}-${APP_THEME_STORAGE_KEY}`,
  });
  const validModes = Object.values(AppThemes) as AppTheme[];
  const rawColorMode = computed(() => {
    const storedMode = colorMode.store.value;
    // Invalid stored values should be overridden with "auto"
    return validModes.includes(storedMode) ? storedMode : "auto";
  });
  const resolvedColorMode = computed(() => colorMode.state.value);

  const usingDark = computed(() => resolvedColorMode.value === AppThemes.DARK);

  const { state: cycleState, next: toggleNextTheme } = useCycleList(Object.values(AppThemes), {
    initialValue: rawColorMode,
  });

  // Keep selected theme in sync with state cycle list
  watchEffect(() => {
    colorMode.value = cycleState.value;
  });

  // Keep Vuetify theme in sync with local/stored theme
  watchEffect(() => {
    // NOTE: Vuetify theme does not support "auto" (must use computed theme name)
    vuetifyTheme.global.name.value = resolvedColorMode.value;
  });

  return {
    appTheme: rawColorMode,
    dark: usingDark,
    icon: computed(() => themeIconMap[rawColorMode.value]),
    setTheme: (theme: AppTheme) => (colorMode.value = theme),
    toggleTheme: () => toggleNextTheme(),
    vuetify: vuetifyTheme.current,
  };
};
