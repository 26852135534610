import { type AppConfig } from "./types";

const production = import.meta.env.PROD;
// TODO: Validate API URL
const apiUrl = import.meta.env.VITE_API_URL ?? "http://localhost:8090";
const appEnvironment = import.meta.env.VITE_APP_ENVIRONMENT ?? "development";

export const appConfig: AppConfig = {
  environment: appEnvironment,
  commitSha: import.meta.env.VITE_GIT_SHA?.slice(0, 8),
  development: !production,
  links: {
    api: apiUrl,
  },
  production,
};
